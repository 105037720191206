import React from 'react';
import styled from 'styled-components';

import { CONSTANS } from '../assets/styles/theme';
import Stars from '../assets/videos/bg.mp4';

const BgVideo = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-width: ${CONSTANS.MIN_PAGE_WIDTH}rem;
  background-color: black;
  z-index: -100;
  object-fit: cover;
  opacity: 0.5;
`;

const StarsBg = () => (
  <BgVideo autoPlay muted loop playsInline>
    <source src={Stars} type="video/mp4" />
  </BgVideo>
);

export default StarsBg;
