import React from 'react';
import Helmet from 'react-helmet';

import GlobalStyle from '../assets/styles/globalStyles';
import StarsBg from '../components/starsBg';

const MainLayout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;1,800&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <GlobalStyle />

      {children}
      <StarsBg />
    </>
  );
};

export default MainLayout;
