import { createGlobalStyle } from 'styled-components';
import { CONSTANS, theme } from './theme';

const GlobalStyle = createGlobalStyle`  
    html {
        box-sizing: border-box;
        font-size: 62.5% !important;
        height: 100%;
    }
    *, *::before, *::after{
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: greyscale; 
    }
    body{
        display: block;
        color: ${theme.color.white};
        background: ${theme.color.black};
        background-color: ${theme.color.black};
        font-family: ${theme.font.family.montserrat};
        font-size: ${theme.font.size.s};
        font-weight: ${theme.font.weight.s}; 
        width: 100%; 
        min-width: ${CONSTANS.MIN_PAGE_WIDTH}rem;
        margin: 0;
        padding: 0; 
         
        ::-webkit-scrollbar {
            width:1rem;
        }
        ::-webkit-scrollbar-track {
            background-color: ${theme.color.black1};
        }
        ::-webkit-scrollbar-thumb {
            background-color: ${theme.color.black2};  
        }
    } 
 
    b{
        font-weight: ${theme.font.weight.l};  
        font-family: ${theme.font.family.montserrat};  
        font-style: italic; 
    }
    h1{
        font-weight: ${theme.font.weight.l};
        font-size: ${theme.font.size.xl}; 
        font-style: italic;  
        padding: 0;
        margin: 0;
        text-transform: uppercase;  

        @media ${theme.device.tablet} {
            font-size: ${theme.font.size.xxl};  
        }
    }
    h2{
        font-weight: ${theme.font.weight.l};
        font-size: ${theme.font.size.l};
        font-style: italic;  
        padding: 0;
        margin: 0;
        text-transform: uppercase;  
    } 
    h3{
        font-weight: ${theme.font.weight.m};
        font-size: ${theme.font.size.m}; 
        padding: 0;
        margin: 0;  
        text-transform: none;  
    } 
    p{
        padding: 0 0;
        margin: 0;  
    }
    a{
        color: inherit; 
        outline: none;
        padding: 0;
        margin: 0;
        text-decoration: none; 
        border: none;  
        
    }   
    .title{
        text-align: center;
        padding-inline: 2rem;
        word-wrap: break-word;
        margin: 10rem auto 2rem;

        @media ${theme.device.tablet} {
            margin: 15rem auto 3rem;
        }
    }

    input,
    textarea,
    button,
    div,
    span,
    i,
    img,
    select,
    a {
        -webkit-tap-highlight-color: transparent;
    }
`;
export default GlobalStyle;
