export const theme = {
  color: {
    black: 'hsl(0,0%,0%)',
    black1: 'hsl(0,0%,12%)',
    black2: 'hsl(0,0%,23%)',
    white: 'hsl(0,0%,100%)',
    white1: 'hsl(0,0%,95%)',
    white2: 'hsl(0,0%,85%)',
    mid: 'hsl(0,0%,50%)',
    green: '#41D562',
    blue: '#14C0F6',
    red: '#FF5E5E',
    redDark: '#7f2121',
    primaryGradient:
      'linear-gradient(135deg, hsl(65,94%,38%) 0%, hsl(162,100%,32%) 100%);',
    transparentWhite: 'hsl(0,0%,100%,3%)',
    transparentWhite1: 'hsl(0,0%,100%,7%)',
    transparentBlack: 'hsl(0,0%,0%,80%)',
  },
  font: {
    size: {
      xs: '1.1rem',
      s: '1.5rem',
      m: '1.8rem',
      l: '3rem',
      xl: '5rem',
      xxl: '6.4rem',
    },
    family: {
      montserrat: 'Montserrat, sans-serif',
    },
    weight: {
      s: '200',
      m: '400',
      l: '800',
    },
  },
  device: {
    mobile: `(min-width: 400px)`,
    tablet: `(min-width: 767px)`,
    laptop: `(min-width: 1200px)`,
    desktop: `(min-width: 1440px)`,
  },
};

export const CONSTANS = {
  MIN_PAGE_WIDTH: 30,
};
